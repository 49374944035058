<template>
  <div class="polygon">
    <transition name="slide-top-small">
      <div class="v-block">
        <ul class="v-title">
          <li :class="currentIndex===item.value?'cell active':'cell'" v-for="(item,index) in navOptions" :key="index" v-show="!(!objectId&&item.value===3)"
            @click="handlerNav(item)">
            <span>{{item.label}}</span></li>
        </ul>
        <div class="v-polygon-content" v-loading="load">
          <template v-if="currentIndex===1">
            <div class="content-top">
              <el-input v-model="key1" prefix-icon="el-icon-search" size="small" @input="d_key1Change"></el-input>
            </div>
            <div class="content-body-check">
              <el-checkbox v-model="checked1" :label="$t('onlineTrack.loadAll')"></el-checkbox>
            </div>
            <el-scrollbar wrap-class="content-body-scroll">
              <el-checkbox-group v-model="checkPOIList">
                <div v-for="(item, index) in (key1 ? POIList_B : POIList)" :key="index" class="content-body-line">
                  <el-checkbox :label="item.PointID" :key="item.PointID" @click.native="poiCheckChangeSingle(item)">
                    {{item.PointName}}</el-checkbox>
                </div>
              </el-checkbox-group>
            </el-scrollbar>
          </template>
          <template v-if="currentIndex===2">
            <div class="content-top">
              <el-input v-model="key2" prefix-icon="el-icon-search" size="small" @input="d_key2Change" class="w1">
              </el-input>
              <tool-tip content="添加多边形围栏" placement="top" type="light-tip" :offset="-10">
                <el-button type="primary" plain @click="handlerPolyonAdd('')" circle v-ripple>
                  <i-icon name="iconxinxiguanli_tianjia_weixuanzhong"></i-icon>
                </el-button>
              </tool-tip>
            </div>
            <div class="content-body-check">
              <el-checkbox v-model="checked2" :label="$t('onlineTrack.loadAll')"></el-checkbox>
            </div>
            <el-scrollbar wrap-class="content-body-scroll">
              <el-checkbox-group v-model="checkRegionList">
                <div v-for="(item, index) in (key2 ? RegionList_B : RegionList)" :key="index" class="content-body-line">
                  <el-checkbox :label="item.RegionID" :key="item.RegionID"
                    @click.native="regionCheckChangeSingle(item)">
                    {{item.RegionName}}</el-checkbox>
                  <span class='tagMode' @click="handlerRelation(1,item)">关联设备</span>
                  <span class='tagEdit' @click="handlerPolyonAdd(item)">编辑</span>
                </div>
              </el-checkbox-group>
            </el-scrollbar>
          </template>
          <template v-if="currentIndex===3">
            <div class="content-top">
              <el-input v-model="key3" prefix-icon="el-icon-search" size="small" @input="d_key3Change"></el-input>
            </div>
            <div class="content-body-check">
              <el-checkbox v-model="checked3" :label="$t('onlineTrack.loadAll')"></el-checkbox>
            </div>
            <el-scrollbar wrap-class="content-body-scroll">
              <el-checkbox-group v-model="checkRegionList">
                <div v-for="(item, index) in (key3 ? RegionListLink_B : RegionListLink)" :key="index"
                  class="content-body-line">
                  <el-checkbox :label="item.RegionID" :key="item.RegionID">
                    {{item.RegionName}}</el-checkbox>
                </div>
              </el-checkbox-group>
            </el-scrollbar>
          </template>
          <template v-if="currentIndex===4">
            <div class="content-top">
              <el-input v-model="key4" prefix-icon="el-icon-search" size="small" @input="d_key4Change" class="w1">
              </el-input>
              <tool-tip content="添加圆形围栏" placement="top" type="light-tip" :offset="-10">
                <el-button type="primary" plain @click="handlerCircleAdd('')" circle v-ripple>
                  <i-icon name="iconxinxiguanli_tianjia_weixuanzhong"></i-icon>
                </el-button>
              </tool-tip>
            </div>
            <div class="content-body-check">
              <el-checkbox v-model="checked4" :label="$t('onlineTrack.loadAll')"></el-checkbox>
            </div>
            <el-scrollbar wrap-class="content-body-scroll">
              <el-checkbox-group v-model="checkRegionCircleList">
                <div v-for="(item, index) in (key4 ? RegionCircleList_B : RegionCircleList)" :key="index"
                  class="content-body-line">
                  <el-checkbox :label="item.CircleID" :key="item.CircleID"
                    @click.native="circleCheckChangeSingle(item)">
                    {{item.CircleName}}</el-checkbox>
                  <span class='tagMode' @click="handlerRelation(2,item)">关联设备</span>
                  <span class='tagEdit' @click="handlerCircleAdd(item)">编辑</span>
                </div>
              </el-checkbox-group>
            </el-scrollbar>
          </template>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import {
    mixinAmap
  } from '@/views/mixins/mixin-poi.js'
  import {
    mapGetters
  } from 'vuex'
  import {
    GetPOI,
    GetRegionByLngLat,
    GetRegionByObjectID,
    GetRegionCircleList
  } from '@/api/common'
  import {
    debounce
  } from '@/common/utils'
  export default {
    props: {
      map: {
        required: true
      },
      google: {
        type: Boolean,
        default: false
      },
      infoWindow: {
        required: true
      },
      objectId: {
        type: Number,
        default: null
      },
      display: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        currentIndex: 1,
        navOptions: [{
            label: this.$t('onlineTrack.poiTab'),
            value: 1
          },
          {
            label: this.$t('onlineTrack.regionTab'),
            value: 2
          },
          {
            label: this.$t('onlineTrack.circleTab'),
            value: 4
          },
          {
            label: this.$t('onlineTrack.regionTabLink'),
            value: 3
          },
        ],
        key1: '',
        key2: '',
        key3: '',
        key4: '',
        checked1: false,
        checked2: false,
        checked3: false,
        checked4: false,
        POIList_B: [],
        RegionList_B: [],
        RegionListLink_B: [],
        RegionCircleList_B: [],
        POIList: [], //兴趣点
        RegionList: [], //围栏
        RegionListLink: [], // 关联围栏
        RegionCircleList: [], //圆形围栏
        checkPOIList: [], //选中的兴趣点
        checkRegionList: [], //选中的围栏
        checkRegionCircleList: [], //选中的圆形围栏
        load: false
      }
    },
    computed: {
      ...mapGetters([
        'loginInfo'
      ]),
    },
    watch: {
      // objectId: {
      //   handler(val) {
      //     console.log(val)
      //   },
      //   deep: true
      // },
      checked1(val) {
        if (val) {
          let list = this.key1 ? this.POIList_B : this.POIList
          list.forEach(k => {
            if (!this.checkPOIList.includes(k.PointID)) {
              this.checkPOIList.push(k.PointID)
            }
          })
          if (process.env.VUE_APP_PLAT === '_kc') {
            this.mixinAddPoiKC(this.map, list.slice()).then(() => {
              this.setFitViewKC(this.map, this.mixinPoiOverlays)
            })
          } else {
            this.mixinAddPoi(this.map, list.slice()).then(() => {
              this.mixinSetFitView(this.map, this.mixinPoiOverlays)
            })
          }
        } else {
          this.checkPOIList.splice(0, this.checkPOIList.length)
          this.mixinRemovePoi()
        }
      },
      checked2(val) {
        if (val) {
          let list = this.key2 ? this.RegionList_B : this.RegionList
          list.forEach(k => {
            if (!this.checkRegionList.includes(k.RegionID)) {
              this.checkRegionList.push(k.RegionID)
            }
          })
          if (process.env.VUE_APP_PLAT === '_kc') {
            this.mixinAddRegionDataToMapKC(this.map, list.slice())
          } else {
            this.mixinAddRegionDataToMap(this.map, list.slice())
          }
          // this.mixinSetFitView(this.map)
        } else {
          this.checkRegionList.splice(0, this.checkRegionList.length)
          this.mixinRemoveRegion()
        }
      },
      checked3(val) {
        if (val) {
          let list = this.key3 ? this.RegionListLink_B : this.RegionListLink
          list.forEach(k => {
            const index = this.checkRegionList.indexOf(k.RegionID)
            if (index == -1) {
              this.checkRegionList.push(k.RegionID)
            }
          })
          if (process.env.VUE_APP_PLAT === '_kc') {
            this.mixinAddRegionDataToMapKC(this.map, list.slice())
          } else {
            this.mixinAddRegionDataToMap(this.map, list.slice())
          }
          // this.mixinSetFitView(this.map)
        } else {
          this.RegionListLink.forEach(k => {
            const index = this.checkRegionList.indexOf(k.RegionID)
            if (index != -1) {
              this.checkRegionList.splice(index, 1)
            }
          })
          this.mixinRemoveRegion()
        }
      },
      checked4(val) {
        if (val) {
          let list = this.key4 ? this.RegionCircleList_B : this.RegionCircleList
          list.forEach(k => {
            const index = this.checkRegionCircleList.indexOf(k.CircleID)
            if (index == -1) {
              this.checkRegionCircleList.push(k.CircleID)
            }
          })
          if (process.env.VUE_APP_PLAT === '_kc') {
            this.mixinAddRegionDataToMapKC(this.map, list.slice())
          } else {
            this.mixinAddCircleDataToMap(this.map, list.slice())
          }
          // this.mixinSetFitView(this.map)
        } else {
          this.RegionCircleList.forEach(k => {
            const index = this.checkRegionCircleList.indexOf(k.CircleID)
            if (index != -1) {
              this.checkRegionCircleList.splice(index, 1)
            }
          })
          this.mixinRemoveCircle()
        }
      },
    },
    created() {
      this.key1Change = () => {
        let key = this.key1
        let list = this.POIList
        this.POIList_B = list.filter(k => k.PointName.indexOf(key) != -1)
      }
      this.key2Change = () => {
        let key = this.key2
        let list = this.RegionList
        this.RegionList_B = list.filter(k => k.RegionName.indexOf(key) != -1)
      }
      this.key3Change = () => {
        let key = this.key3
        let list = this.RegionListLink
        this.RegionListLink_B = list.filter(k => k.RegionName.indexOf(key) != -1)
      }
      this.d_key4Change = () => {
        let key = this.key4
        let list = this.RegionCircleList
        this.RegionCircleListk_B = list.filter(k => k.CircleName.indexOf(key) != -1)
      }
      this.d_key1Change = debounce(this.key1Change, 300)
      this.d_key2Change = debounce(this.key2Change, 300)
      this.d_key3Change = debounce(this.key3Change, 300)
      this.d_key4Change = debounce(this.key4Change, 300)
    },
    methods: {
      handlerNav(item) {
        this.currentIndex = item.value
        this.fetchDataList(item.value)
      },
      handlerPolyonAdd(row) {
        this.$emit('editPolyon', row)
      },
      handlerCircleAdd(row) {
        this.$emit('editCircle', row)
      },
      handlerRelation(index, row) {
        const temp = {
          type: index,
          obj: row
        }
        this.$emit('relation', temp)
      },
      poiCheckChangeSingle(item) {
        setTimeout(() => {
          if (this.checkPOIList.includes(item.PointID)) {
            if (process.env.VUE_APP_PLAT !== '_kc') {
              this.mixinSelectPoi(this.map, item)
            } else {
              this.mixinSelectPoiKC(this.map, item)
            }
          } else {
            let index = null
            if (process.env.VUE_APP_PLAT === '_kc') {
              index = this.mixinPoiOverlays.findIndex(k => k.extID === item.PointID)
            } else {
              index = this.mixinPoiOverlays.findIndex(k => k.getExtData() === item.PointID)
            }

            if (index != -1) {
              this.mixinPoiOverlays[index].setMap(null)
              this.mixinPoiOverlays[index] = null
              this.mixinPoiOverlays.splice(index, 1)
            }
          }
        }, 50)
      },
      regionCheckChangeSingle(item) {
        setTimeout(() => {
          if (this.checkRegionList.includes(item.RegionID)) {
            if (process.env.VUE_APP_PLAT === '_kc') {
              this.mixinSelectRegionKC(this.map, item)
            } else {
              this.mixinSelectRegion(this.map, item)
            }
          } else {
            let index = null
            if (process.env.VUE_APP_PLAT === '_kc') {
              index = this.mixinRegionOverlays.findIndex(k => k.extID === item.RegionID)
            } else {
              index = this.mixinRegionOverlays.findIndex(k => k.getExtData() === item.RegionID)
            }

            if (index != -1) {
              this.mixinRegionOverlays[index].setMap(null)
              this.mixinRegionOverlays[index] = null
              this.mixinRegionOverlays.splice(index, 1)
            }
          }
        }, 50)
      },
      circleCheckChangeSingle(item) {
        setTimeout(() => {
          if (this.checkRegionCircleList.includes(item.CircleID)) {
            if (process.env.VUE_APP_PLAT === '_kc') {
              this.mixinSelectRegionKC(this.map, item)
            } else {
              this.mixinSelectCircle(this.map, item)
            }
          } else {
            let index = null
            if (process.env.VUE_APP_PLAT === '_kc') {
              index = this.mixinCircleOverlays.findIndex(k => k.extID === item.CircleID)
            } else {
              index = this.mixinCircleOverlays.findIndex(k => k.getExtData() === item.CircleID)
            }

            if (index != -1) {
              this.mixinCircleOverlays[index].setMap(null)
              this.mixinCircleOverlays[index] = null
              this.mixinCircleOverlays.splice(index, 1)
            }
          }
        }, 50)
      },
      fetchDataList(eq) {
        const index = eq || this.currentIndex
        this.load = true
        if (index === 1) {
          this.fetchPoiData()
        } else if (index === 2) {
          this.fetchRegionData()
        } else if (index === 3) {
          this.fetchRegionByID()
        } else if (index === 4) {
          this.fetchRegionCircleData()
        }
      },
      fetchPoiData() {
        const promise = new Promise((resolve, reject) => {
          GetPOI('', true).then(ret => {
            this.POIList = ret.data.splice(0, 500)
            resolve()
          }).catch(err => {
            reject(err)
          }).finally(() => {
            this.load = false
          })
        })
        return promise
      },
      fetchRegionData() {
        const promise = new Promise((resolve, reject) => {
          const bounds = this.map.getBounds()
          // 西南角
          const point_xinan = bounds.getSouthWest()
          // 东北角
          const point_dongbei = bounds.getNorthEast()
          if (process.env.VUE_APP_PLAT === '_kc') {
            point_xinan.lng = bounds.La.i
            point_xinan.lat = bounds.La.g
            point_dongbei.lng = bounds.Ua.i
            point_dongbei.lat = bounds.Ua.g
          } else {
            point_xinan.lng = point_xinan.getLng()
            point_xinan.lat = point_xinan.getLat()
            point_dongbei.lng = point_dongbei.getLng()
            point_dongbei.lat = point_dongbei.getLat()
          }
          GetRegionByLngLat(point_xinan.lng, point_xinan.lat, point_dongbei.lng, point_dongbei.lat).then(ret => {
            //console.log(ret.data)
            this.RegionList = ret.data
            resolve()
          }).catch(err => {
            reject(err)
          }).finally(() => {
            this.load = false
          })
        })
        return promise
      },
      fetchRegionCircleData() {
        const promise = new Promise((resolve, reject) => {
          GetRegionCircleList(1, 40, true, this.loginInfo.HoldID).then(ret => {
            //console.log(ret.data)
            this.RegionCircleList = ret.data.data
            resolve()
          }).catch(err => {
            reject(err)
          }).finally(() => {
            this.load = false
          })
        })
        return promise
      },
      fetchRegionByID() {
        if (!this.objectId) {
          this.RegionListLink = []
          this.load = false
          return
        }
        const promise = new Promise((resolve, reject) => {
          GetRegionByObjectID(this.objectId).then(ret => {
            this.RegionListLink = ret.data
            resolve()
          }).catch(err => {
            reject(err)
          }).finally(() => {
            this.load = false
          })
        })
        return promise
      },
    },
    mounted() {
      this.fetchDataList()
    },
    mixins: [
      mixinAmap
    ]
  }

</script>

<style lang="scss" scoped>
  .polygon {
    position: absolute;
    z-index: 110;
    left: 10px;
    top: 60px;

    .v-block {
      width: 360px;
      border-radius: 5px;
      background-color: hsla(0, 0%, 100%, .8);
      position: relative;
      z-index: 1;
      border-top: none;

      .v-title {
        height: 36px;
        line-height: 36px;
        text-align: center;
        cursor: pointer;
        display: -ms-flexbox;
        display: flex;
        border-bottom: 1px solid #ccc;

        .cell {
          -ms-flex: 1 1 50%;
          flex: 1 1 50%;
          text-align: center;
          cursor: pointer;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          position: relative;
          top: 1px;
          border-bottom: 1px solid transparent;
          font-size: 16px;
          color: rgba(0, 0, 0, .54);
        }

        .active {
          color: #1890ff;
          border-bottom: 1px solid #1890ff;
        }
      }

      .v-polygon-content {
        .content-top {
          display: -ms-flexbox;
          display: flex;
          padding: 0 18px;
          margin-top: 15px;

          .w1 {
            width: 90%;
          }

          & ::v-deep .el-input__inner {
            border-radius: 20px;
          }

          & ::v-deep .el-button.is-circle {
            padding: 8px;
            margin-left: 10px;
          }
        }

        .content-body-check {
          height: 36px;
          line-height: 36px;
          padding-left: 12px;
          position: relative;
          z-index: 0;
        }

        .content-body-line {
          height: 36px;
          line-height: 36px;
          padding-left: 12px;
          position: relative;
          z-index: 0;

          .tagEdit {
            cursor: pointer;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 1;
            padding: 0 10px;
            font-size: 14px;
            color: #1890ff;
          }

          .tagMode {
            cursor: pointer;
            position: absolute;
            right: 50px;
            top: 0;
            z-index: 1;
            padding: 0 10px;
            font-size: 14px;
            color: #1890ff;
          }
        }

        & ::v-deep .content-body-scroll {
          height: 200px;
        }
      }

    }

  }

</style>
